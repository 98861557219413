import React from 'react';

import { useSelector } from 'react-redux';

import { AnalyticsProvider } from 'reaxl-analytics';
import { useFeatures } from 'reaxl-features';
import { Header } from 'reaxl-header-ford';

import fbaAnalyticsHandlers from '@/analytics/fbaAnalyticsHandlers';
import useDataLayer from '@/analytics/useDataLayer';

import { birfDuck } from '@/ducks';

export default function FordHeader() {
    const {
        disable_thirdparty: [disableThirdParty],
        google_analytics: [, { disableGtm, disableGtmEvents, delay }],
    } = useFeatures([
        'disable_thirdparty',
        'google_analytics',
    ]);

    const dataLayer = useDataLayer();
    const dataIsland = useSelector(birfDuck.selectors.getPageData);

    const fordLogo = {
        src: 'https://www.autotrader.com/content/static/img/partner/ford/ford-header-logo.svg',
        alt: 'Ford Blue Advantage logo',
        width: '199',
        height: '48',
    };
    const atcFordLogo = {
        src: 'https://www.autotrader.com/content/static/img/partner/ford/ford-header-atc-logo.svg',
        alt: 'Autotrader logo',
        width: '88',
        height: '22',
    };

    const analyticsProps = {
        dataLayer: () => ({ ...dataLayer }),
        getDataIsland: () => ({ ...dataIsland }),
        google: {
            delay,
        },
        value: {
            ...fbaAnalyticsHandlers,
        },
        option: {
            isOptimizelyEdge: true,
            disableGTM: disableGtm || disableThirdParty,
            disableGTMEvents: disableGtmEvents,
            disablePixall: disableThirdParty,
        },
    };

    return (
        <AnalyticsProvider {...analyticsProps}>
            <Header
                atcFordLogo={atcFordLogo}
                fordLogo={fordLogo}
            />
        </AnalyticsProvider>
    );
}
